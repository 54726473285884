module.exports = {
  admin: require('./admin')
  apcsp: require('./apcsp')
  analyticsLogEvents: require('./analytics-log-events')
  campaigns: require('./campaigns')
  clas: require('./clas')
  classrooms: require('./classrooms')
  contact: require('./contact')
  courses: require('./courses')
  courseInstances: require('./course-instances')
  files: require('./files')
  levels: require('./levels')
  levelSessions: require('./level-sessions')
  markdown: require('./markdown')
  prepaids: require('./prepaids')
  resourceHubResources: require('./resource_hub_resource')
  skippedContacts: require('./skipped-contacts')
  thangTypes: require('./thang-types')
  trialRequests: require('./trial-requests')
  userCodeProblems: require('./user-code-problems')
  users: require('./users')
  apiClients: require('./api-clients')
  oauth: require('./o-auth')
  outcomesReports: require('./outcomes-reports')
  archivedElements: require('./archived-elements')
}
