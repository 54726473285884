var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Math, jqxhr, me, view) {pug_html = pug_html + "\u003Cdiv class=\"text-center\" id=\"loading-error\"\u003E";
if (!jqxhr) {
pug_html = pug_html + "\u003Ch1 data-i18n=\"loading_error.unknown\"\u003E\u003C\u002Fh1\u003E";
}
else
if (jqxhr.status === 401) {
pug_html = pug_html + "\u003Ch1\u003E\u003Cspan class=\"spr\"\u003E401:\u003C\u002Fspan\u003E\u003Cspan data-i18n=\"loading_error.login_required\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fh1\u003E\u003Cp data-i18n=\"loading_error.login_required_desc\"\u003E\u003C\u002Fp\u003E\u003Cbutton class=\"login-btn btn btn-primary\" data-i18n=\"login.log_in\"\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-primary\" id=\"create-account-btn\" data-i18n=\"login.sign_up\"\u003E\u003C\u002Fbutton\u003E\u003C!-- 402 currently not in use. TODO: Set it up--\u003E";
}
else
if (jqxhr.status === 402) {
pug_html = pug_html + "\u003Ch2\u003E402: Payment Required\u003C\u002Fh2\u003E";
}
else
if (jqxhr.status === 403) {
pug_html = pug_html + "\u003Ch1\u003E\u003Cspan class=\"spr\"\u003E403:\u003C\u002Fspan\u003E\u003Cspan data-i18n=\"loading_error.forbidden\"\u003EForbidden\u003C\u002Fspan\u003E\u003C\u002Fh1\u003E\u003Cp data-i18n=\"loading_error.forbidden_desc\"\u003E\u003C\u002Fp\u003E\u003C!-- this should make no diff... but sometimes the server returns 403 when it should return 401--\u003E";
if (!me.isAnonymous()) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-primary\" id=\"logout-btn\" data-i18n=\"login.log_out\"\u003E\u003C\u002Fbutton\u003E";
}
}
else
if (jqxhr.status === 404) {
pug_html = pug_html + "\u003Ch1\u003E\u003Cspan class=\"spr\"\u003E404:\u003C\u002Fspan\u003E\u003Cspan data-i18n=\"loading_error.not_found\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fh1\u003E";
var num = Math.floor(Math.random() * 3) + 1;
pug_html = pug_html + "\u003Cimg" + (" id=\"not-found-img\""+pug.attr("src", '/images/pages/not_found/404_' + num + '.png', true, true)) + "\u003E\u003Cp data-i18n=\"loading_error.not_found_desc\"\u003E\u003C\u002Fp\u003E";
}
else
if (!jqxhr.status) {
pug_html = pug_html + "\u003Ch1 data-i18n=\"loading_error.connection_failure\"\u003E\u003C\u002Fh1\u003E\u003Cp data-i18n=\"loading_error.connection_failure_desc\"\u003E\u003C\u002Fp\u003E";
}
else {
if (jqxhr.status === 408) {
pug_html = pug_html + "\u003Ch1\u003E\u003Cspan class=\"spr\"\u003E408:\u003C\u002Fspan\u003E\u003Cspan data-i18n=\"loading_error.timeout\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fh1\u003E";
}
else
if (jqxhr.status >= 500 && jqxhr.status <= 599) {
pug_html = pug_html + "\u003Ch1\u003E\u003Cspan class=\"spr\"\u003E" + (pug.escape(null == (pug_interp = jqxhr.status) ? "" : pug_interp)) + ":\u003C\u002Fspan\u003E\u003Cspan data-i18n=\"loading_error.server_error\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fh1\u003E";
}
else {
pug_html = pug_html + "\u003Ch1 data-i18n=\"loading_error.unknown\"\u003E\u003C\u002Fh1\u003E";
}
pug_html = pug_html + "\u003Cp data-i18n=\"loading_error.general_desc\"\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"row\" id=\"links-row\"\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Cul class=\"list-unstyled\"\u003E\u003Cli\u003E\u003Cstrong data-i18n=\"common.help\"\u003E\u003C\u002Fstrong\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"\u002F\" data-i18n=\"nav.home\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca" + (pug.attr("href", view.forumLink(), true, true)+" data-i18n=\"nav.forum\"") + "\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
if (!me.isStudent()) {
pug_html = pug_html + "\u003Cli\u003E\u003Ca class=\"contact-modal\" tabindex=\"-1\" data-i18n=\"nav.contact\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
pug_html = pug_html + "\u003Cli\u003E\u003Ca href=\"\u002Fcommunity\" data-i18n=\"nav.community\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Cul class=\"list-unstyled\"\u003E\u003Cli\u003E\u003Cstrong data-i18n=\"courses.students\"\u003E\u003C\u002Fstrong\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"\u002Fstudents\" data-i18n=\"nav.learn_to_code\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
if (me.isAnonymous()) {
pug_html = pug_html + "\u003Cli\u003E\u003Ca class=\"login-btn\" data-i18n=\"login.log_in\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
pug_html = pug_html + "\u003Cli\u003E\u003Ca href=\"\u002Fstudents\" data-i18n=\"courses.join_class\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Cul class=\"list-unstyled\"\u003E";
if (me.isAnonymous()) {
pug_html = pug_html + "\u003Cli\u003E\u003Ca class=\"login-btn\" data-i18n=\"login.log_in\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
}
pug_html = pug_html + "\u003Cli\u003E\u003Ca href=\"\u002Fteachers\u002Fclasses\" data-i18n=\"nav.create_a_class\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Cul class=\"list-unstyled\"\u003E\u003Cli\u003E\u003Cstrong data-i18n=\"nav.other\"\u003E\u003C\u002Fstrong\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"http:\u002F\u002Fblog.codecombat.com\u002F\" data-i18n=\"nav.blog\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"https:\u002F\u002Fwww.facebook.com\u002Fcodecombat\" data-i18n=\"nav.facebook\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"https:\u002F\u002Ftwitter.com\u002Fcodecombat\" data-i18n=\"nav.twitter\"\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"Math" in locals_for_with?locals_for_with.Math:typeof Math!=="undefined"?Math:undefined,"jqxhr" in locals_for_with?locals_for_with.jqxhr:typeof jqxhr!=="undefined"?jqxhr:undefined,"me" in locals_for_with?locals_for_with.me:typeof me!=="undefined"?me:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;