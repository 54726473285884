var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Date, _, state, view) {pug_html = pug_html + "\u003Cform class=\"modal-body segment-check\"\u003E\u003Cdiv class=\"modal-body-content\"\u003E";
switch (view.signupState.get('path')){
case 'student':
pug_html = pug_html + "\u003Cdiv class=\"modal-student-contents\"\u003E\u003Cdiv class=\"class-code-section\"\u003E\u003Cp data-i18n=\"signup.yes_i_have_classcode\"\u003E\u003C\u002Fp\u003E\u003Clabel for=\"classCode\" data-i18n=\"signup.enter_it_here\"\u003E\u003C\u002Flabel\u003E\u003Cdiv class=\"class-code-input-group form-group\"\u003E\u003Cdiv class=\"input-with-glyph\"\u003E\u003Cinput" + (" class=\"class-code-input\""+" name=\"classCode\""+pug.attr("value", view.signupState.get('classCode'), true, true)) + "\u003E\u003Cdiv class=\"render\"\u003E";
if (!(_.isEmpty(view.signupState.get('classCode')))) {
if (state.get('classCodeValid')) {
pug_html = pug_html + "\u003Cspan class=\"glyphicon glyphicon-ok-circle class-code-valid-icon\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"glyphicon glyphicon-remove-circle class-code-valid-icon\"\u003E\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbutton" + (" class=\"primary-oz-button next-button\""+" type=\"submit\""+pug.attr("disabled", !state.get('classCodeValid'), true, true)) + "\u003E\u003Cspan data-i18n=\"common.continue\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"render\"\u003E";
if (state.get('classCodeValid')) {
pug_html = pug_html + "\u003Cspan class=\"spr\" data-i18n=\"signup.about_to_join\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"spr\"\u003E" + (pug.escape(null == (pug_interp = view.classroom.get('name')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (view.classroom.owner.get('firstName') || view.classroom.owner.get('name')) {
pug_html = pug_html + "\u003Cspan class=\"spr\" data-i18n=\"signup.with\"\u003E\u003C\u002Fspan\u003E";
if (view.classroom.owner.get('firstName')) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = view.classroom.owner.get('firstName') + " " + view.classroom.owner.get('lastName')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else
if (view.classroom.owner.get('name')) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = view.classroom.owner.get('name')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
}
else
if (!_.isEmpty(view.signupState.get('classCode'))) {
pug_html = pug_html + "\u003Cdiv class=\"ask-teacher\"\u003E";
if (state.get('codeExpired')) {
pug_html = pug_html + "\u003Cspan class=\"spr\" data-i18n=\"signup.activation_code_used\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"spr\" data-i18n=\"signup.classroom_not_found\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"spr\" data-i18n=\"signup.ask_teacher_2\"\u003E\u003C\u002Fspan\u003E\u003Ca class=\"individual-path-button\"\u003E\u003Cspan data-i18n=\"signup.ask_teacher_3\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Cspan class=\"spl\" data-i18n=\"signup.ask_teacher_4\"\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"divider-line\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"play-now-section\"\u003E\u003Cdiv\u003E\u003Cp data-i18n=\"signup.want_to_play_codecombat\"\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cbutton class=\"primary-oz-button play-now\"\u003E\u003Cspan data-i18n=\"new_home.play_now\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case 'teacher':
pug_html = pug_html + "\u003C!-- TODO--\u003E";
  break;
case 'individual':
if (view.signupState.get('subModalContinue')) {
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.sign_in_to_continue\"\u003E\u003C\u002Fspan\u003E\u003Cbr\u003E";
}
else
if (view.signupState.get('accountRequiredMessage')) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = view.signupState.get('accountRequiredMessage')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbr\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"birthday-form-group form-group\"\u003E\u003Cspan data-i18n=\"signup.enter_birthdate\"\u003E\u003C\u002Fspan\u003E\u003Cdiv class=\"input-border\"\u003E\u003Cselect class=\"input-large form-control\" id=\"birthday-month-input\" name=\"birthdayMonth\" style=\"width: 106px; float: left\"\u003E\u003Coption value=\"\" data-i18n=\"calendar.month\"\u003E\u003C\u002Foption\u003E";
// iterate ['january','february','march','april','may','june','july','august','september','october','november','december']
;(function(){
  var $$obj = ['january','february','march','april','may','june','july','august','september','october','november','december'];
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var name = $$obj[index];
var month = index + 1
pug_html = pug_html + "\u003Coption" + (pug.attr("data-i18n", ('calendar.' + name), true, true)+pug.attr("value", month, true, true)+pug.attr("selected", (month === view.signupState.get('birthdayMonth')), true, true)) + "\u003E\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var name = $$obj[index];
var month = index + 1
pug_html = pug_html + "\u003Coption" + (pug.attr("data-i18n", ('calendar.' + name), true, true)+pug.attr("value", month, true, true)+pug.attr("selected", (month === view.signupState.get('birthdayMonth')), true, true)) + "\u003E\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003Cselect class=\"input-large form-control\" id=\"birthday-day-input\" name=\"birthdayDay\" style=\"width: 75px; float: left\"\u003E\u003Coption value=\"\" data-i18n=\"calendar.day\"\u003E\u003C\u002Foption\u003E";
// iterate _.range(1,32)
;(function(){
  var $$obj = _.range(1,32);
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var day = $$obj[pug_index1];
pug_html = pug_html + "\u003Coption" + (pug.attr("selected", (day === view.signupState.get('birthdayDay')), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = day) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var day = $$obj[pug_index1];
pug_html = pug_html + "\u003Coption" + (pug.attr("selected", (day === view.signupState.get('birthdayDay')), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = day) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003Cselect class=\"input-large form-control\" id=\"birthday-year-input\" name=\"birthdayYear\" style=\"width: 90px; float: left\"\u003E\u003Coption value=\"\" data-i18n=\"calendar.year\"\u003E\u003C\u002Foption\u003E";
var thisYear = new Date().getFullYear()
// iterate _.range(thisYear, thisYear - 100, -1)
;(function(){
  var $$obj = _.range(thisYear, thisYear - 100, -1);
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var year = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug.attr("selected", (year === view.signupState.get('birthdayYear')), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = year) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var year = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug.attr("selected", (year === view.signupState.get('birthdayYear')), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = year) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"parent_birthdate\" data-i18n=\"signup.parent_use_birthdate\"\u003E\u003C\u002Fdiv\u003E";
  break;
default:
pug_html = pug_html + "\u003Cp\u003E\u003Cspan\u003ESign-up error, please contact\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Ca href=\"mailto:support@codecombat.com\"\u003Esupport@codecombat.com\u003C\u002Fa\u003E.\u003C\u002Fp\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (view.signupState.get('path') != 'student') {
pug_html = pug_html + "\u003C!-- In reverse order for tabbing purposes--\u003E\u003Cdiv class=\"history-nav-buttons\"\u003E\u003Cbutton class=\"next-button btn btn-lg btn-navy\" type=\"submit\"\u003E\u003Cspan data-i18n=\"common.continue\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
if (!view.signupState.get('subModalContinue')) {
pug_html = pug_html + "\u003Cbutton class=\"back-to-account-type btn btn-lg btn-navy-alt\" type=\"button\"\u003E\u003Cspan data-i18n=\"common.back\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fform\u003E";}.call(this,"Date" in locals_for_with?locals_for_with.Date:typeof Date!=="undefined"?Date:undefined,"_" in locals_for_with?locals_for_with._:typeof _!=="undefined"?_:undefined,"state" in locals_for_with?locals_for_with.state:typeof state!=="undefined"?state:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;