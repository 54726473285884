var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (me, window) {pug_mixins["button-hello-mivan"] = pug_interp = function(type, buttonText, description, imageAlt, imageSRC){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"col-md-6 path-card\"\u003E\u003Cimg" + (pug.attr("class", pug.classes(["card-image",type], [false,true]), false, true)+pug.attr("data-i18n", imageAlt, true, true)+pug.attr("src", imageSRC, true, true)) + "\u003E\u003Cdiv class=\"card-footer\"\u003E\u003Cspan class=\"small\"\u003E\u003Cdiv" + (pug.attr("data-i18n", description, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["primary-oz-button",type], [false,true]), false, true)) + "\u003E\u003Cspan" + (pug.attr("data-i18n", buttonText, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"modal-body-content\"\u003E";
if (me.get("preferredLanguage") == "he" || window.location.host == "il.codecombat.com") {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-info text-center\" dir=\"rtl\"\u003E\u003Cspan\u003Eתלמיד מערכת החינוך?\u003C\u002Fspan\u003E\u003Ca href=\"https:\u002F\u002Fpub.skillz-edu.org\u002Fportal\u002Fplayground\"\u003E התחבר דרך פורטל משרד החינוך\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
if (me.showIndividualRegister()) {
pug_html = pug_html + "\u003Cdiv class=\"row path-cards\"\u003E";
pug_mixins["button-hello-mivan"]("teacher-path-button","new_home.im_an_educator", "new_home.im_an_educator_description", '[alt]new_home.for_educators', '/images/pages/account/create/educator.png');
pug_mixins["button-hello-mivan"]("parent-path-button","new_home.im_a_parent", "new_home.im_a_parent_description", '[alt]new_home.for_parents', '/images/pages/account/create/parent.png');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"row path-cards\"\u003E";
pug_mixins["button-hello-mivan"]("student-path-button","new_home.im_a_student", "new_home.im_a_student_description", '[alt]new_home.for_students', '/images/pages/account/create/student.png');
pug_mixins["button-hello-mivan"]("individual-path-button","new_home.im_an_individual", "new_home.im_an_individual_description", '[alt]new_home.for_individuals', '/images/pages/account/create/individual.png');
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"row path-cards\"\u003E";
pug_mixins["button-hello-mivan"]("teacher-path-button","new_home.im_an_educator", "new_home.im_an_educator_description", '[alt]new_home.for_educators', '/images/pages/account/create/educator.png');
pug_mixins["button-hello-mivan"]("student-path-button","new_home.im_a_student", "new_home.im_a_student_description", '[alt]new_home.for_students', '/images/pages/account/create/student.png');
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"me" in locals_for_with?locals_for_with.me:typeof me!=="undefined"?me:undefined,"window" in locals_for_with?locals_for_with.window:typeof window!=="undefined"?window:undefined));;return pug_html;};
module.exports = template;